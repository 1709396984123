import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { MenuItem } from 'types/global';

type TentOccupancyProps = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
	open?: boolean;
};

export default function ParkingOccupancy({ selected, onSelect, open }: TentOccupancyProps) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/parkingOccupancy"
			selected={selected === MenuItem.ParkingOccupancy}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) =>
				onSelect(event, MenuItem.ParkingOccupancy)
			}
		>
			<ListItemIcon>
				<LocalParkingIcon />
			</ListItemIcon>
			{open && <ListItemText primary={t('common:occupations')} />}
		</ListItem>
	);
}
