import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

import { Save as SaveIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	FormControl,
	FormGroup,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	LinearProgress,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import AccessTickets from 'components/AccessTickets';
import useUser from 'hooks/useUser';
import { enqueueSnackbarError } from 'lib/helpers';
import { editAccess, fetchDetailCode, saveAccess, saveAccessTent } from 'lib/models/access';
import moment, { Moment } from 'moment';
import { ClearIcon } from '@mui/x-date-pickers';

type Props = {
	vehicles: Vehicle[];
	loading: boolean;
	sponsor: string;
	accessId: string | null;
};

function GenerateAccessForm({ vehicles, sponsor, accessId }: Props) {
	const { t } = useTranslation();

	const { user } = useUser();

	const isAdmin = user?.type === 'admin' || user?.type === 'parkingAdmin';
	const isCashier = user?.type === 'parkingCashier';

	const [isPrinting, setIsPrinting] = useState<boolean>(false);

	const [loading, setLoading] = useState<boolean>(false);

	const [quantity, setQuantity] = useState<number | ''>(1);

	const [accessCodeInfo, setAccessCodeInfo] = useState<Access>(getDefaultAccess());

	const [accessCodes, setAccessCodes] = useState<Access[]>([]);

	const [startDate, setStartDate] = useState<Moment | null>(moment());

	const [endDate, setEndDate] = useState<Moment | null>(moment());

	const [canSave, setCanSave] = useState<boolean>(false);

	useEffect(() => {
		const getParamsAccess = async () => {
			try {
				if (accessId) {
					const response = await fetchDetailCode(accessId);
					if (!response) throw new Error('Codigo inexistente');

					setAccessCodeInfo(response);
					setIsPrinting(false);
				} else {
					setAccessCodeInfo(getDefaultAccess());
				}
			} catch (error) {
				console.error(error);
			}
		};

		getParamsAccess();
	}, [accessId]);

	useEffect(() => {
		const { tent, type, description, qrType, email } = accessCodeInfo;
		const today = moment();
		const sameDay = startDate?.isSame(today, 'day') && endDate?.isSame(today, 'day');
		const multipleConditions =
			qrType === 'multiple' && !!tent && !!description && !!email && !!startDate && !!endDate && isAdmin;

		const simpleConditions = qrType === 'simple' && type && (isAdmin || isCashier);

		const hasDuplicates = hasDuplicatePlates(accessCodeInfo.plate);

		if ((multipleConditions || simpleConditions || (qrType === 'simple' && type && sameDay)) && !hasDuplicates) {
			setCanSave(true);
		} else {
			setCanSave(false);
		}
	}, [accessCodeInfo, isAdmin, endDate, startDate, isCashier]);

	const onUpdate = (key: keyof Access) => {
		return (event: any) => {
			const value = event.target.value;

			if (accessCodeInfo) {
				setAccessCodeInfo({ ...accessCodeInfo, [key]: value });
			}
		};
	};

	const onUpdateQRType = (qrType: 'simple' | 'multiple') => {
		setAccessCodeInfo({ ...getDefaultAccess(), qrType });
		setQuantity(1);
	};

	const onUpdateDate = (date: Moment | null, key: keyof ValidDate) => {
		if (accessCodeInfo && date) {
			const updated = { ...accessCodeInfo };

			updated.validDate[key] = new Date(date.format()).toString();

			setAccessCodeInfo(updated);
		}
	};

	const onUpdatePlates = (plate: string, index: number) => {
		const uppercasePlate = plate.toUpperCase();

		setAccessCodeInfo((prev) => {
			let newPlates = prev.plate;
			newPlates[index] = uppercasePlate;

			return { ...prev, plate: newPlates };
		});
	};

	const save = async () => {
		try {
			setLoading(true);
			if (!startDate || !endDate) {
				throw new Error('Invalid dates');
			}
			const start = startDate.toISOString();
			const end = endDate.toISOString();

			const { type, tent, email, plate, slots, description, qrType } = accessCodeInfo;

			if (accessId) {
				cleanPlate();
				const access = await editAccess(accessCodeInfo.slots < 1 ? { ...accessCodeInfo, plate: [] } : accessCodeInfo);
				setAccessCodes([access]);
				setIsPrinting(true);
			} else if (qrType === 'simple' && quantity && typeof type === 'string') {
				const accessCodes = await saveAccess(start, end, quantity, type);
				setAccessCodes(accessCodes);
				setIsPrinting(true);
			} else if (qrType === 'multiple' && tent && typeof type === 'string' && email && slots && description) {
				cleanPlate();
				const accessCode =
					slots < 1
						? await saveAccessTent(start, end, type, tent, [], slots, email, description)
						: await saveAccessTent(start, end, type, tent, plate, slots, email, description);
				setAccessCodes([accessCode]);
				setIsPrinting(true);
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
	};

	const isEditing = !!accessId;

	const onAddPlate = () => {
		if (accessCodeInfo.slots > 0) {
			setAccessCodeInfo((prev) => ({ ...prev, plate: [...prev.plate, ''] }));
		}
	};

	const onRemovePlate = (index: number) => {
		setAccessCodeInfo((prev) => {
			const newPlate = [...prev.plate];
			newPlate.splice(index, 1);
			return { ...prev, plate: newPlate };
		});
	};

	const cleanPlate = () => {
		if (accessCodeInfo.slots === 0) {
			setAccessCodeInfo((prev) => {
				return { ...prev, plate: [] };
			});
		}
	};

	return (
		<Box id="generate-access-form-container">
			{loading && <LinearProgress />}
			{!isPrinting && (
				<FormGroup sx={{ pb: 4 }}>
					<FormControl>
						<InputLabel id="qrType">Tipo de QR</InputLabel>

						<Select
							labelId="qrType"
							id="qrType"
							label={'Tipo de QR'}
							fullWidth={true}
							value={accessCodeInfo.qrType || 'simple'}
							variant="outlined"
							sx={{ mb: 3 }}
							onChange={(e) => onUpdateQRType(e.target.value as 'multiple' | 'simple')}
							autoComplete="off"
							disabled={!!accessId}
						>
							<MenuItem value={'simple'}>{'Simple'}</MenuItem>
							{isAdmin && <MenuItem value={'multiple'}>{'Múltiple'}</MenuItem>}
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<InputLabel id="type-label">Tipo de acceso</InputLabel>
						<Select
							labelId="type"
							id="type"
							label="Tipo de vehículo"
							fullWidth={true}
							value={typeof accessCodeInfo.type === 'object' ? accessCodeInfo.type._id : accessCodeInfo.type}
							variant="outlined"
							sx={{
								mb: 3,
								'& .MuiInputLabel-root': {
									color: 'blue',
								},
							}}
							onChange={onUpdate('type')}
							disabled={isEditing}
						>
							{vehicles?.map((vehicle) => {
								return (
									<MenuItem value={vehicle._id} selected={true}>
										{vehicle.description}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					{accessCodeInfo.qrType === 'multiple' && (
						<>
							<TextField
								placeholder="Nro. de carpa, CUIT del proveedor, nro. de empleado, etc."
								autoComplete="off"
								id="tent"
								label={'ID'}
								fullWidth={true}
								value={accessCodeInfo.tent}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('tent')}
							/>
							<TextField
								autoComplete="off"
								id="description"
								label={'Descripción'}
								placeholder="Nombre y Apellido, Razón Social, etc."
								fullWidth={true}
								value={accessCodeInfo.description}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('description')}
							/>
							<TextField
								autoComplete="off"
								id="email"
								label={t('common:Email')}
								fullWidth={true}
								value={accessCodeInfo.email}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={onUpdate('email')}
							/>

							{
								<>
									<TextField
										type="number"
										autoComplete="off"
										id="slots"
										label={t('access:slots')}
										fullWidth={true}
										value={accessCodeInfo.slots}
										variant="outlined"
										sx={{ mb: 3 }}
										onChange={onUpdate('slots')}
									/>

									{!isNaN(accessCodeInfo.slots) && accessCodeInfo.slots > 0 && (
										<Grid container spacing={2} columns={10} sx={{ mb: 3 }} alignItems="center" width={'full'}>
											{Array.from({ length: accessCodeInfo.plate.length }, (_, i) => i).map((n) => {
												const plateValue = accessCodeInfo.plate[n]?.replace(/\s/g, '');
												const isDuplicate =
													plateValue &&
													accessCodeInfo.plate
														.map((p) => p?.replace(/\s/g, ''))
														.filter((p, index) => p === plateValue && p !== '' && index !== n).length > 0;

												return (
													<Grid item xs={5} lg={2}>
														<TextField
															type="text"
															autoComplete="off"
															id={`slot${n + 1}`}
															label={`Patente ${n + 1}`}
															fullWidth={true}
															value={accessCodeInfo.plate[n]}
															variant="outlined"
															onChange={(e) => onUpdatePlates(e.target.value, n)}
															error={!!isDuplicate}
															helperText={isDuplicate ? 'Esta patente está repetida' : ''}
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton onClick={() => onRemovePlate(n)} edge="end" size="small">
																			<ClearIcon fontSize="small" />
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</Grid>
												);
											})}

											<IconButton color="primary" sx={{ mt: 2 }} onClick={onAddPlate}>
												<AddIcon />
											</IconButton>
										</Grid>
									)}

									<DatePicker
										sx={{ marginBottom: '24px' }}
										value={startDate}
										format="DD/MM/YYYY"
										label={'Fecha de inicio de estadía'}
										onChange={(ev) => {
											onUpdateDate(ev, 'from');
											setStartDate(ev);
										}}
										minDate={moment()}
									/>
									<DatePicker
										sx={{ marginBottom: '24px' }}
										value={endDate}
										format="DD/MM/YYYY"
										label={'Fecha de fin de estadía'}
										onChange={(ev) => {
											onUpdateDate(ev, 'to');
											setEndDate(ev);
										}}
										minDate={startDate ? moment(startDate) : moment()}
									/>
								</>
							}
						</>
					)}
					{accessCodeInfo.qrType === 'simple' && !accessId && (
						<>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={startDate}
								format="DD/MM/YYYY"
								label={'Crear desde la fecha'}
								onChange={(ev) => {
									setStartDate(ev);
								}}
								minDate={moment()}
								disabled={!isAdmin && !isCashier}
							/>
							<DatePicker
								sx={{ marginBottom: '24px' }}
								value={endDate}
								format="DD/MM/YYYY"
								label={'Hasta la fecha'}
								onChange={(ev) => {
									setEndDate(ev);
								}}
								minDate={startDate}
								disabled={!isAdmin && !isCashier}
							/>

							<TextField
								type="number"
								inputProps={{ min: 1 }}
								autoComplete="off"
								id="quantity"
								label={'Cantidad de QRs por cada día'}
								fullWidth={true}
								value={quantity}
								variant="outlined"
								sx={{ mb: 3 }}
								onChange={(e) => {
									const value = Number(e.target.value);
									setQuantity(value === 0 ? '' : value);
								}}
							/>
						</>
					)}

					<Button
						variant="contained"
						color="primary"
						size="large"
						sx={{ m: 1 }}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={!canSave}
					>
						{t('access:generate')}
					</Button>
				</FormGroup>
			)}
			{isPrinting && (
				<AccessTickets accessCodes={accessCodes} loading={loading} setLoading={setLoading} sponsor={sponsor} />
			)}
		</Box>
	);
}

export default GenerateAccessForm;

function getDefaultAccess(type?: string): Access {
	return {
		code: '',
		plate: [],
		type: type || '',
		qrType: 'simple',
		tent: '',
		email: '',
		validDate: {},
		movements: [],
		vehiclesInside: 0,
		isActive: true,
		slots: 1,
		description: '',
	};
}

const hasDuplicatePlates = (plates: string[]) => {
	const normalizedPlates = plates.filter((plate) => plate).map((plate) => plate.replace(/\s/g, ''));

	const uniquePlates = new Set();

	for (const plate of normalizedPlates) {
		if (uniquePlates.has(plate)) {
			return true;
		}
		uniquePlates.add(plate);
	}

	return false;
};
